






import MoreInfo from '@/components/Layout/MoreInfo.vue'
export default {
    name: 'UserMain',
    components: {
        MoreInfo
    }
}
